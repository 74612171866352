exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-capture-js": () => import("./../../../src/pages/capture.js" /* webpackChunkName: "component---src-pages-capture-js" */),
  "component---src-pages-consult-js": () => import("./../../../src/pages/consult.js" /* webpackChunkName: "component---src-pages-consult-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-edit-js": () => import("./../../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-holosuite-js": () => import("./../../../src/pages/holosuite.js" /* webpackChunkName: "component---src-pages-holosuite-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playback-js": () => import("./../../../src/pages/playback.js" /* webpackChunkName: "component---src-pages-playback-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-stream-js": () => import("./../../../src/pages/stream.js" /* webpackChunkName: "component---src-pages-stream-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-modular-js": () => import("./../../../src/templates/modular.js" /* webpackChunkName: "component---src-templates-modular-js" */)
}

